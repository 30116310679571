let tabbed_content_links = document.querySelectorAll('.content-tabber--actions li a');

tabbed_content_links.forEach((el, index) => {
    el.addEventListener( 'click', content_tabber_event );
});

function content_tabber_event(e) {

    e.preventDefault();

    let el       = e.currentTarget;
    let tabber   = window.helpers.parents(el, '.content-tabber')[0];
    let target   = el.getAttribute('href');
    let tab      = document.querySelector('.content-tabber--tabs ' + target);
    let tabs_sel = tabber.querySelectorAll(`:scope ${'.content-tabber--actions a'}`);
    let tabs     = tabber.querySelectorAll(`:scope ${'.content-tabber--tab'}`);
    
    tabs_sel.forEach((tabbed_links_el, index) => {
        tabbed_links_el.classList.remove('active');
    });

    tabs.forEach((tabbed_content_el, index) => {
        window.helpers.hide(tabbed_content_el);
    });

    el.classList.add('active');
    
    window.helpers.fadeIn(tab, 'block');

}